var Emico = Emico || {};

Emico.ScrollTo = class {
    constructor(options) {
        this.scrollTo = this.scrollTo.bind(this);

        this.options = jQuery.extend({}, {
            scrollBtnSelector: '.scroll-to'
        }, options);

        this.scrollTo();
    }

    scrollTo() {
        const scrollButton = jQuery(this.options.scrollBtnSelector);

        scrollButton.on('click', (event) => {
            event.preventDefault();

            const currentTarget = jQuery(event.currentTarget);

            const scrollToSelector = currentTarget.attr('href') || currentTarget.attr('data-scroll-to');
            const scrollOffset = currentTarget.attr('data-scroll-offset') || 70;


            jQuery('html, body').animate({
                scrollTop: jQuery(scrollToSelector).offset().top - scrollOffset
            }, 300);
            return true;
        });
    }
};

jQuery(function () {
    new Emico.ScrollTo();
});